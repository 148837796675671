import React from "react"
import RootElementBaseLosMurales from "acciondigital-basic-theme/src/components/losmurales/rootElementBaseLosMurales"
import useSiteMetadata from "acciondigital-basic-theme/src/hooks/useSiteMetadata"
import { getInfoUsuarioSite } from "../utils/siteUtils"

export const RootElement = ({ children }) => {
  const { siteId } = useSiteMetadata()
  return (
    <RootElementBaseLosMurales sitio={siteId} getInfoUsuarioSite={getInfoUsuarioSite}>
      {children}
    </RootElementBaseLosMurales>
  )
}

export default RootElement
