export const getLinkBotonPerfilNego = (afiliado, siteUrl, nego_slug) =>
  !afiliado
    ? ""
    : "https://wa.me/" +
      (afiliado?.telefono
        ? "521" + afiliado.telefono.split(" ").join("")
        : "") +
      "?text=" +
      encodeURIComponent(
        "Hola buen día, - " +
          afiliado.nombre +
          "\n" +
          siteUrl +
          "/nego/" +
          nego_slug
      )

export const getTextoRecomendarPerfilNego = afiliado =>
  !afiliado
    ? ""
    : "Hola buen día, te quiero recomendar el negocio << " + afiliado.nombre

export const getInfoUsuarioSite = authContextData => {
  const {
    user,
    // infoControl,
    infoUsuarios: { suscripciones = {}, negos = {}, membresias = {} } = {},
    // infoUsuariosControl: { tiendas = {} } = {},
  } = authContextData ?? {}

  const suscripcionesLst = Object.values(suscripciones)
  const negosLst = Object.values(negos)
  const membresiasLst = Object.values(membresias)

  // console.debug("🚀 ~ file: siteUtils.js:63 ~ getInfoUsuarioSite ~ getInfoUsuarioSite:", user?.uid)

  // const tieneSuscripcionVigente = validarSuscripcionVigenteAF(suscripcionesLst)
  // // suscripcionVigente(getSuscripcionVigente(suscripcionesLst))

  // const membresiaVigente_COMPRADOR_MAYORISTA = getMembresiaVigente_COMPRADOR_MAYORISTA(membresiasLst)

  const tieneNego = negosLst?.[0]?.slug
  const nego = negosLst?.[0]

  // const susPorVencer = getSuscripcionVencimientoMesAF(suscripcionesLst, 0)
  // const susVencida = tieneSuscripcionVencidaAF(suscripcionesLst)

  return {
    user,
    nombre: user?.displayName,
    foto: user?.photoURL,
    email: user?.email,
    lastLoginAt: user?.metadata?.lastSignInTime,
    createdAt: user?.metadata?.creationTime,
    suscripciones,
    suscripcionesLst,
    negos,
    negosLst,
    nego,
    membresias,
    membresiasLst,
    uid: user?.uid,
    user_uid: user?.uid,
    //   tieneSuscripcionVigente,
    tieneNego,
    //   susPorVencer,
    //   susVencida,
    //   membresiaVigente_COMPRADOR_MAYORISTA
  }
}
